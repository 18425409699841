import axios from 'axios'
import { useState } from 'react'
import { setIdToken } from '@utils/UserInfo'
import { toast, toastClear } from '@utils/Toaster'

export function LoginBox({ backendUrl }) {
  const [isSuccess, setIsSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (event) => {
    event.preventDefault() // don't redirect the page
    toastClear()

    if (event.target.identifier.value && event.target.code.value) {
      setIsLoading(true)

      let payload = {
        username: event.target.identifier.value,
        password: event.target.code.value,
      }

      event.target.code.value = ''

      axios({
        method: 'post',
        url: backendUrl + '/user/login/check',
        headers: {},
        data: payload,
      })
        .then(function (response) {
          // handle success
          if (response.data && response.data.token) {
            setIsSuccess(true)
            setIdToken(response.data.token)
            setTimeout(function () {
              window.location.reload()
            }, 666)
          }
        })
        .catch((error) => {
          if (500 == error.response.status) {
            toast('error', 'Internal Server Error')
          }else if (404 == error.response.status) {
            toast('error', 'Server Error - Backend Failure')
          }else if (400 == error.response.status || 401 == error.response.status) {
            toast('error', error.response.data.message)
          } else {
            toast('error', 'Server Error')
          }

          setIsLoading(false)
        })
        .then(function () {
          // always executed
          setIsLoading(false)
        })
    }
  }

  return (
    <form autoComplete="off" onSubmit={handleSubmit} className="grid">
      <div className="flex flex-wrap items-center justify-center gap-2 my-1">
        <input
          autoComplete="on"
          type="text"
          className="input input-bordered w-full max-w-xs"
          id="identifier"
          name="identifier"
          placeholder="Benutzername"
          required
        />
      </div>

      <div className="flex flex-wrap items-center justify-center gap-2 my-1">
        <input
          autoComplete="off"
          type="text"
          className="input input-bordered w-full max-w-xs"
          id="code"
          name="code"
          placeholder="Code"
          required
        />
      </div>

      <div className="flex flex-wrap items-center justify-center gap-2 my-1">
        {!isLoading && !isSuccess && (
          <button type="submit" className="btn btn-primary w-full max-w-xs">
            Login
          </button>
        )}

        {isLoading && !isSuccess && (
          <button className="btn btn-primary w-full max-w-xs" type="button" disabled>
            <span
              className="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>{' '}
            Login...
          </button>
        )}

        {isSuccess && (
          <button className="btn btn-success w-full max-w-xs" type="button" disabled>
            <span
              className="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>{' '}
            Login erfolgreich...
          </button>
        )}
      </div>
    </form>
  )
}
